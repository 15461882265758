import React, { useState } from "react"
import styled from "styled-components";
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import {Section, SectionHero, SectionPageTitle, SectionImageBkg, SectionTitle, ExploreMores, SectionDescription} from "../components/Section"
import {FeatureGrid, FeatureGridLeft, FeatureGridRight, FeatureGridInner, FeatureGridItem, Mascot, FeatureTitle, ListFeatures, ListFeatureItem} from "../components/Features"
import {LoadingIcon, PhoneIcon} from "../components/Icons"
import DefaultButton from "../components/Button/DefaultButton"
import CategoryLandingProductCardList from "../components/ProductCardList/CategoryLandingProductCardList"
import StructuresTypes from "../components/StructuresTypes"
import ColorPicker from "../components/ColorPicker"
// import PhotoModal from "../components/PhotoModal"


const SectionBanner = styled(SectionHero)`
  position:relative;
`
const SectionDescriptions = styled(SectionDescription)`
  margin-bottom:15px;
  @media (min-width:768px){
    margin-bottom:0;
  }
`

const HeroCaption = styled.div`	
  width: 100%;
  padding:30px 0;
  @media (min-width:768px){
    padding:40px 0;
  }
  @media (min-width:992px){
    padding:60px 0 60px;
  }
  @media (min-width:1200px){
    padding: 90px 0 70px;
  }
`
const HeroGrid = styled.div`
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:space-between;
  margin:0 -15px;
`
const HeroGridLeft = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  @media (min-width:768px){
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

`
const HeroGridRight = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  @media (min-width:768px){
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
`
const HeroMarkText = styled.div`
  max-width:920px;
  width:100%;
  margin:0 auto;
  background-color:#1383C1;
  color:#fff;
  text-align:center;
  max-width: 930px;
  width: 100%;
  margin: 0 auto;
  background-color: #1383C1;
  color: #fff;
  text-align: center;
  z-index: 1;
  position: relative; 
  
  padding: 2px 20px;
  margin-bottom:-16px;
  @media(min-width:1200px){
    margin-bottom:-17px;
  }
  @media(min-width:1600px){
    margin-bottom:-22px;
  }
  p{
    margin-bottom:0;
    font-weight:500;

    font-size:16px;
    line-height:24px; 
    font-weight:500;
    @media(min-width:1200px){
      font-size:20px;
      line-height:32px; 
    }
    @media(min-width:1600px){
      font-size:24px;
      line-height:40px; 
      font-weight:700;
    }
  }
`

const SectionFeature = styled(Section)`
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  padding:40px 0;
  @media(min-width:992px){
    padding:60px 0;
  }
  @media(min-width:1600px){
    padding:90px 0;
  }
`

const SectionColor = styled(Section)`
  border-top:1px solid #ccc;
  .color-palette{
    padding-bottom:0;
  }
`

const SectionImageBkgs = styled(SectionImageBkg)`
    display:none;
  @media(min-width:992px){
    display:block;
  }
`
const SectionTitleCustom = styled(SectionTitle)`
  text-align:center;
  margin-bottom:60px;
  @media(min-width:1200px){
    margin-bottom:100px;
  }
`

const PhotosPage = ({ data, location }) => {
  const [showLimit, setShowLimit] = useState(12);

  const productData = data.allContentfulProduct.edges;

  return(
    <Layout location={location}>
      <Seo
        title={data.allContentfulPageData.edges[0].node.metaTitle}
        description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
      />
      <SectionBanner className="section-hero">
        <SectionImageBkg opacity="0.1"><StaticImage src="../images/inside-center-carport.jpg" alt="Photos" /></SectionImageBkg>
        <HeroCaption>
          <div className="container">
            <HeroGrid>
              <HeroGridLeft>
                <SectionPageTitle className="h2 mb-0">Our Installed Metal Buildings</SectionPageTitle>
                <SectionDescriptions fontStyle="italic"><p>Check out the steel building pictures that our building experts have designed and built for different dealers.</p></SectionDescriptions>
              </HeroGridLeft>
              <HeroGridRight>
                <a href="tel:3869610006" aria-label="Phone"><DefaultButton icon={<PhoneIcon />} text="386-961-0006" size="lg" animate="ripple-black"/></a>
              </HeroGridRight>
            </HeroGrid>
          </div>
        </HeroCaption>
        <HeroMarkText><p>Customized Buildings Built and Installed by Tubular Building Systems Professionals</p></HeroMarkText>
      </SectionBanner>

      <Section pt="60px" pb="60px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#fff" className="section-category">
        <div className="container">
          <CategoryLandingProductCardList data={productData} showLimit={showLimit} />
          {productData.length > showLimit && (
            <ExploreMores>
              <button type="button" aria-label="button" onClick={() => setShowLimit(showLimit + 6)}><DefaultButton primary text="Load More buildings…" icon={<LoadingIcon />} animate="ripple-black" /></button>                            
            </ExploreMores>
          )}
        </div>
      </Section>

      <SectionColor pt="80px" pb="0" xpt="60px" mpt="60px"  bgColor="#fff" >
        <div className="container"><SectionTitleCustom>Available Color Options</SectionTitleCustom></div>
        <ColorPicker />
      </SectionColor>

      <SectionFeature pt="0" pb="0" bgColor="#fff" className="section-features">	
        <SectionImageBkgs width="30%" opacity="0.05"><StaticImage src="../images/inside-circle-carport.png" alt="inside-circle-carport" /></SectionImageBkgs>
        <div className="container">
          <FeatureGrid>
            <FeatureGridLeft>
              <Mascot><StaticImage src="../images/mascot-watermark.png" alt="mascot-watermark" /></Mascot>
            </FeatureGridLeft>
            <FeatureGridRight>
              <FeatureGridInner>
                <FeatureGridItem>
                  <FeatureTitle>STANDARD FEATURES:</FeatureTitle>
                  <ListFeatures>
                    <ListFeatureItem><mark>29 GAUGE METAL ROOFING</mark></ListFeatureItem>
                    <ListFeatureItem><mark>14 GAUGE GALVANIZED STEEL FRAMING</mark></ListFeatureItem>
                    <ListFeatureItem><mark>5' ON-CENTER LEG SPACING</mark></ListFeatureItem>
                    <ListFeatureItem><mark>6' STANDARD LEG HEIGHT</mark></ListFeatureItem>
                    <ListFeatureItem><mark>BRACING ON EVERY ROOF PEAK</mark></ListFeatureItem>
                    <ListFeatureItem><mark>BRACING ON EVERY LEG</mark></ListFeatureItem>
                    <ListFeatureItem><mark>ANCHORING INCLUDED</mark></ListFeatureItem>
                    <ListFeatureItem><mark>17 COLOR CHOICES FOR EACH: ROOF, SIDES, & TRIM</mark></ListFeatureItem>
                  </ListFeatures>
                </FeatureGridItem>
                <FeatureGridItem>
                  <StructuresTypes />
                </FeatureGridItem>
              </FeatureGridInner>
            </FeatureGridRight>
          </FeatureGrid>
        </div>
      </SectionFeature>
      
    </Layout>
  )
}
export default PhotosPage

export const pageQuery = graphql`
  query PhotosPageQuery{
    allContentfulProduct(sort: {fields: skuNumber}) {
      edges {
        node {
          sku
          cardImage {
            gatsbyImageData(quality: 90)
          }
        }
      }
    }
    allContentfulPageData(filter: {page: {eq: "Metal Buildings Insulation"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
  }
`